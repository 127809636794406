<template>
  <div class="blogpost">
    <Navigator />
    <div class="bpost">
      <div class="bp-c">
        <div class="blpost-c">
          <p class="titulo-p">{{ data.title }}</p>
          <p class="data">
            {{ data.dateblog }} <span>|</span> Por: {{ data.autor_name }}
            {{ data.autor_lastname }}
          </p>
          <img :src="srcImg(data.image)" alt="postimg" class="imagepost" />
          <p class="descr">{{ data.descrp }}</p>

          <!-- cuerpo del blog -->
          <div v-for="(bloq, key) in data.body" :key="key" class="bloq-item">
            <div class="mbbloq">
              <div
                v-if="bloq.tipo == 'texto'"
                v-html="bloq.texto"
                class="S1F1"
              ></div>
            </div>

            <div
              v-if="bloq.tipo == 'video'"
              v-html="bloq.video"
              class="video"
            ></div>

            <div v-if="bloq.tipo == 'mapa'" class="bloq_mapa" >
                <div  v-html="bloq.ficha" class="ficha_mapa"></div>
                <div  v-html="bloq.mapa" class="iframe_mapa"></div>
            </div>


            <div v-if="bloq.tipo == 'frase'" class="fraseSq">
              <p class="B2Mesage" :class="{ B2Mesage2: bloq.tipo != 'frase' }">
                “{{ bloq.frase.texto }}”.
              </p>
              <p class="B2Autor">/ {{ bloq.frase.firma }}</p>
            </div>

            <div v-if="bloq.tipo == 'frase_destacada'" class="mbbloq comillas">
              <p class="B4F5_P">"{{ bloq.frase_destacada }}"</p>
            </div>

            <img
              v-if="bloq.tipo == 'imagen'"
              :src="srcImg(bloq.imagen)"
              alt="imagen bloque"
              class="img_bloq"
              :class="{'img_chica' : bloq.tam_imagen === 'chica', 'img_mediana' : bloq.tam_imagen === 'mediana', 'img_grande' : bloq.tam_imagen === 'grande' }"
            />
            <p  v-if="bloq.tipo == 'imagen'" v-html="bloq.pie_imagen" class="pie_imagen"></p>
            <div v-if="bloq.tipo == 'caja'" class="B2F1">
              <div class="w50">
                <div
                  v-if="bloq.caja[0].tipo == 'texto'"
                  v-html="bloq.caja[0].texto"
                  class="B3F1_P"
                ></div>
                <img
                  :class="{'img_chica' : bloq.caja[0].tam_imagen === 'chica', 'img_mediana' : bloq.caja[0].tam_imagen === 'mediana', 'img_grande' : bloq.caja[0].tam_imagen === 'grande' }"
                  v-if="bloq.caja[0].tipo == 'imagen'"
                  :src="srcImg(bloq.caja[0].imagen)"
                  alt="imagen bloque"
                />
                <p  v-if="bloq.caja[0].tipo == 'imagen'" v-html="bloq.caja[0].pie_imagen" class="pie_imagen"></p>
                <div
                  v-if="bloq.caja[0].tipo == 'frase'"
                  class="fraseSq comillas"
                >
                  <p class="B2Mesage">“{{ bloq.caja[0].frase.texto }}”.</p>
                  <p class="B2Autor">/ {{ bloq.caja[0].frase.firma }}</p>
                </div>

                <div
                  v-if="bloq.caja[0].tipo == 'frase_destacada'"
                  class="comillas"
                >
                  <p class="B3F4_P">"{{ bloq.caja[0].frase_destacada }}"</p>
                </div>
              </div>

              <div class="mlfcaja2 w50">
                <div
                  v-if="bloq.caja[1].tipo == 'texto'"
                  v-html="bloq.caja[1].texto"
                  class="B3F1_P"
                ></div>

                <img
                  v-if="bloq.caja[1].tipo == 'imagen'"
                  :src="srcImg(bloq.caja[1].imagen)"
                  alt="imagen bloque"
                  :class="{'img_chica' : bloq.caja[1].tam_imagen === 'chica', 'img_mediana' : bloq.caja[1].tam_imagen === 'mediana', 'img_grande' : bloq.caja[1].tam_imagen === 'grande' }"
                />
                <p  v-if="bloq.caja[1].tipo == 'imagen'" v-html="bloq.caja[1].pie_imagen" class="pie_imagen"></p>
                <div
                  v-if="bloq.caja[1].tipo == 'frase'"
                  class="fraseSq comillas"
                >
                  <p class="B2Mesage">“{{ bloq.caja[1].frase.texto }}”.</p>
                  <p class="B2Autor">/ {{ bloq.caja[1].frase.firma }}</p>
                </div>

                <div
                  v-if="bloq.caja[1].tipo == 'frase_destacada'"
                  class="comillas"
                >
                  <p class="B3F4_P">"{{ bloq.caja[1].frase_destacada }}"</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tags">
            <p>TAGS:</p>
            <p v-for="(t, i) in data.tags" :key="i"
              >#{{ t.name }}</p
            >
          </div>

          <div class="colaboradores-c" v-if="data.colaborador.name">
            <img
              :src="srcImg2(data.colaborador.image)"
              alt="colaborador"
              class="colabo-i"
              v-if="data.colaborador.image !== null"
            />
            <div class="collab-info">
              <p class="t">SOBRE {{ data.colaborador.name }}</p>
              <div class="desc" v-html="data.colaborador.about"></div>
              <a
                :href="data.colaborador.instagram"
                class="sm-i"
                target="_blank"
                rel="noopener noreferrer"
                ><img src="../assets/iig.png" alt="img"
              /></a>
            </div>
          </div>

          <div class="alt-posts">
            <p class="titulo">También podrías estar interesado en:</p>
            <div class="al-posts" v-for="(t, i) in blogs" :key="i">
              <a :href="'/blog-post/' + t.url">
                <p class="t">{{ t.title }}</p>
                <p class="d">{{ t.dateblog }}</p>
              </a>
            </div>
          </div>
        </div>
        <div class="widget-c">
          <WebSMenu />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Navigator from "../components/items-page/Navigator";
import dayjs from "dayjs";
let urlpath = require("../global/url");
import Footer from "../components/items-page/Footer";
import WebSMenu from "../components/items-page/WebSMenu";

export default {
  components: {
    Navigator,
    Footer,
    WebSMenu,
  },
  async created() {
    let payload = {
      id: this.$route.params.id,
      option: "blog-url",
    };
    await this.getInfoByIdBlo(payload);
    this.getAllInfoBlo3("blog");
  },
  computed: {
    data() {
      let array = this.$store.getters["blog/data"];
    
      return array;
    },
    blogs() {
      let array = this.$store.getters["blog/data3"];
      if(!Array.isArray(array)) return []
      
      array = array.filter(item => item.statusblog ==='VISIBLE');
      /*
      console.log(filtered)*/
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array.slice(0, 3);
    },
  },
  methods: {
    ...mapActions("blog", ["getInfoByIdBlo"]),
    ...mapActions("blog", ["getAllInfoBlo3"]),
    srcImg: function (img) {
      let src = `${urlpath.url()}/blog-img/${img}`;
      return src;
    },
    srcImg2: function (img) {
      let src = `${urlpath.url()}/colaboradores-img/${img}`;
      return src;
    },
  },
};
</script>

<style>

.bp-c {
  display: block;
  justify-content: center;
}
/***post */
.bpost .blpost-c {
  width: 80.435vw;
  margin: auto;
}
.bpost img.imagepost {
  width: 100%;
}
.bpost p.titulo-p {
  font-size: 7vw !important;
  line-height: 9vw;
  font-weight: 900;
  text-align: center;
  font-family: var(--RedHat);
  margin: 0 auto;
  width: 75%;
}
.bpost p.data {
  font-size: 3.14vw;
  color: #999;
  text-align: center;
  font-family: var(--RedHat);
  margin: 2vw auto 9vw;
}
.bpost p.descr {
  font-size: 3.797vw;
  line-height: 3.865vw;
  font-weight: 600;
    margin: 11vw 0;
}
.bloq-item p {
  font-size: 3.797vw;
  line-height: 6.865vw;
}
.bloq-item img {
  width: 100%;
}
.bloq-item p.B4F5_P, .bloq-item .B3F4_P, .bloq-item p.B2Mesage {
  font-style: italic;
  font-weight: 600;
  margin: 0;
  padding: 6.039vw 0 6.039vw 14.493vw;
}
.bloq-item p.B2Autor {
  font-style: italic;
  font-weight: 600;
  margin: 0;
  padding: 2.039vw 0 6.039vw 14.493vw;
}
.bloq-item p.quote {
  position: absolute;
  font-size: 3.797vw;
  line-height: 3.865vw;
}
.bloq-item p.quote {
  position: absolute;
  font-size: 18.797vw;
  line-height: 18.865vw;
  margin: 0;
  font-family: auto;
  color: gray;
}
.bloq-item .video iframe {
  width: 100%;
  height: 46vw;
}
.comillas, .fraseSq {
  background-image: url("../assets/comillas.png");
  background-size: 10vw;
  background-repeat: no-repeat;
}
.tags {
  display: flex;
  padding-top: 19vw;
  padding-bottom: 5vw;
  line-height: 3.14vw;
  font-size: 2.899vw;
  border-bottom: 0.15vw solid gainsboro;
  margin: 0;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}
.tags p:first-child{
  font-weight: 700;
}
.tags p{
  margin: 2px 10px;
}
.tags span a {
  color: #272727 !important;
  padding: 0 1.74vw;
}
.al-posts {
  padding: 4.831vw 7.246vw;
  border: 0.4vw solid rgb(239, 239, 239);
  border-bottom: 0.4vw solid #a1a1a1;
  margin-bottom: 8vw;
  background-color: transparent;
  transition: .5s;
}
.al-posts:hover{
  background-color: rgb(243, 243, 243);
}
.al-posts .t {
  margin-top: 0;
  margin-bottom: 1.208vw;
  font-size: 4.348vw;
  color: black !important;
}
.al-posts .d {
  color: #999;
  font-size: 2.899vw;
}
.colaboradores-c {
  padding-top: 9vw;
  padding-bottom: 9vw;
}
.colaboradores-c .colabo-i {
  width: 50%;
  margin: auto;
  display: flex;
}
.colaboradores-c .t {
  text-transform: uppercase;
  font-size: 4.831vw;
  font-weight: 600;
}
.colaboradores-c .desc p {
  text-transform: uppercase;
  font-weight: 500;
}
.colaboradores-c .sm-i img{
  width: 15vw;
}
.bloq-item {
    margin: 11vw 0;
}
.mlfcaja2 {
    margin-top: 10vw;
}

  .iframe_mapa iframe{
    width: 78vw;
}

.pie_imagen p{
    font-size: 2vw !important;
    margin: 0vw;
    line-height: normal !important;
  }
@media (min-width: 768px) {
  .bp-c{
    display: flex;
  }
  .bpost .blpost-c{
    margin: 0%;
  }
  .bloq_mapa {
  margin-top: 1vw;
  margin-bottom: 1vw;
}
.iframe_mapa {
  margin-top: 2vw;
}
  .iframe_mapa iframe{
  width: 44.01vw;
}

.colaboradores-c .sm-i img{
  width: 2vw;
}
.mlfcaja2 {
    margin-top: 0;
}
  .widget-c {
    display: block;
    padding-left: 6.125vw;
  }
  .bpost .blpost-c {
    width: 44.01vw;
  }
  .bpost p.titulo-p {
    font-size: 2vw !important;
    line-height: 2.5vw !important;
    text-align: left;
    width: 100%;
  }
  .bpost p.data {
    font-size: 0.677vw;
    margin: 1vw auto 1vw;
    text-align: left;
  }
  .bpost p.descr {
    font-size: 1.2vw;
    line-height: 1.3vw;
    margin: 2vw 0 2vw;
  }
  .bloq-item {
      margin: 1.5vw 0;
  }
  .bloq-item p {
    font-size: 0.885vw !important;
    margin:  0;/**/
    line-height: 1.3vw;
  }
  .B2F1 {
    display: flex;
    justify-content: space-between;
  }
  .bloq-item img {
    width: 100%;
    margin: auto;
    display: flex;
}
.bloq-item .w50 img{
    width: 100%;
}
  .tags {
    padding-top: 0;
    padding-bottom: 4vw;
    line-height: 0.677vw;
    font-size: 0.625vw;
  }
  .tags span {
    margin-left: .4vw;
  }
  .tags span a {
    padding: 0px 0.74vw;
  }
  .colaboradores-c {
   padding-top: 4vw;
   padding-bottom: 4vw;
    display: flex;
  }
  .colaboradores-c .colabo-i {
    width: 7.813vw;
    margin: auto 2vw;
  }
  .colaboradores-c .t {
    font-size: 0.677vw;
  }
  .al-posts {
    padding: 1.831vw 1.246vw;
    border: 0.1vw solid rgb(239, 239, 239);
    border-bottom: 0.1vw solid #a1a1a1;
    margin-bottom: 1vw;
}
  .al-posts .t {
    margin-bottom: 0.208vw;
    font-size: 0.677vw;
  }
  .al-posts .d {
    font-size: 0.677vw;
  }
  .bloq-item .video iframe {
    height: 25vw;
}
.comillas, .fraseSq { 
    background-size: 3vw; 
}
.bloq-item p.B4F5_P, .bloq-item .B3F4_P, .bloq-item p.B2Mesage {
    padding: 1.039vw 0 1.039vw 3.493vw;
    font-size: 0.785vw !important;
    margin: 0;
}.bloq-item p.B2Autor {
    padding: .39vw 0 1.039vw 3.493vw;
    font-size: 0.785vw !important;
    margin: 0;
}
.w50 {
    width: 45%;
}
.alt-posts {
    padding-bottom: 4vw;
}
.pie_imagen{
  margin-top: 1vw !important;
  width: 100%;
}
.pie_imagen p{
    font-size: .7vw !important;
    margin: 0vw;
    line-height: normal !important;
  }
}
</style>